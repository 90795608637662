<template>
  <div>
    <vs-card>
      <!-- <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button color="primary" type="filled" @click="addTeam()"
            >Add Team</vs-button
          >
        </vs-col> </vs-row
      ><br /> -->

      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-card>
            <div>
              <vs-table search :data="teamList">
                <template slot="header">
                  <h3>
                    Teams
                  </h3>
                </template>
                <template slot="thead">
                  <vs-th sort-key="name">Team</vs-th>
                  <vs-th sort-key="week_off">Week Off</vs-th>
                  <vs-th sort-key="status">Action</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].name">
                      {{ data[indextr].name }}
                    </vs-td>
                    <vs-td :data="data[indextr].week_off">
                      {{ data[indextr].week_off }}
                    </vs-td>
                    <vs-td :data="data[indextr].id">
                      <vs-row>
                        <vs-col vs-type="flex" vs-w="12">
                          <vs-button
                            size="small"
                            radius
                            color="success"
                            type="gradient"
                            icon="create"
                            @click="
                              editTeam(
                                data[indextr].id,
                                data[indextr].name,
                                data[indextr].week_off
                              )
                            "
                          ></vs-button
                          >&nbsp;&nbsp;
                          <!-- <vs-button size="small" radius color="danger" type="gradient" icon="delete" @click="deleteTeam(data[indextr].id,data[indextr].name,data[indextr].week_off)"></vs-button>&nbsp;&nbsp; -->
                        </vs-col>
                      </vs-row>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vs-card>
        </vs-col>
      </vs-row>
    </vs-card>
    <!-- Add teams -->
    <vs-popup title="Add Team Form" :active.sync="addteamform">
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          wz
        >
          <vs-input
            class="inputx"
            maxlength="20"
            style="width:80%"
            placeholder="New Team Name "
            v-model="addteamName"
          />
        </vs-col> </vs-row
      ><br />

      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <h4>Week-Off</h4>
        </vs-col> </vs-row
      ><br />
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <span
            ><vs-checkbox v-model="weekoff" vs-value="Sunday"
              >Sunday</vs-checkbox
            ></span
          >
          <span
            ><vs-checkbox v-model="weekoff" vs-value="Monday"
              >Monday</vs-checkbox
            ></span
          >
          <span
            ><vs-checkbox v-model="weekoff" vs-value="Tuesday"
              >Tuesday</vs-checkbox
            ></span
          >
          <span
            ><vs-checkbox v-model="weekoff" vs-value="Wendsday"
              >Wendsday</vs-checkbox
            ></span
          >
        </vs-col> </vs-row
      ><br />
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <span
            ><vs-checkbox v-model="weekoff" vs-value="Thursday"
              >Thursday</vs-checkbox
            ></span
          >
          <span
            ><vs-checkbox v-model="weekoff" vs-value="Friday"
              >Friday</vs-checkbox
            ></span
          >
          <span
            ><vs-checkbox v-model="weekoff" vs-value="Saturday"
              >Saturday</vs-checkbox
            ></span
          >
        </vs-col> </vs-row
      ><br />
      <vs-row style="justify-content: center;">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <vs-button size="small" @click="createTeam()">Add</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>

    <!-- edit teams -->
    <vs-popup title="Edit Team Form" :active.sync="editteamform">
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          wz
        >
          <vs-input
            class="inputx"
            maxlength="20"
            style="width:80%"
            placeholder="New Team Name "
            v-model="editTeamName"
          />
        </vs-col> </vs-row
      ><br />

      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <h4>Week-Off</h4>
        </vs-col> </vs-row
      ><br />
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <span
            ><vs-checkbox v-model="editWeekoff" vs-value="Sunday"
              >Sunday</vs-checkbox
            ></span
          >
          <span
            ><vs-checkbox v-model="editWeekoff" vs-value="Monday"
              >Monday</vs-checkbox
            ></span
          >
          <span
            ><vs-checkbox v-model="editWeekoff" vs-value="Tuesday"
              >Tuesday</vs-checkbox
            ></span
          >
          <span
            ><vs-checkbox v-model="editWeekoff" vs-value="Wendsday"
              >Wendsday</vs-checkbox
            ></span
          >
        </vs-col> </vs-row
      ><br />
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <span
            ><vs-checkbox v-model="editWeekoff" vs-value="Thursday"
              >Thursday</vs-checkbox
            ></span
          >
          <span
            ><vs-checkbox v-model="editWeekoff" vs-value="Friday"
              >Friday</vs-checkbox
            ></span
          >
          <span
            ><vs-checkbox v-model="editWeekoff" vs-value="Saturday"
              >Saturday</vs-checkbox
            ></span
          >
        </vs-col> </vs-row
      ><br />
      <vs-row style="justify-content: center;">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <vs-button size="small" @click="editingnTeam()">Add</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
require("vue-simple-calendar/static/css/default.css");
// import router from "@/router";
// import moment from "moment";
import axios from "axios";
import constants from "../../constants.json";

import Datepicker from "vuejs-datepicker";
export default {
  components: {
    CalendarView,
    CalendarViewHeader,
    Datepicker,
  },
  data() {
    return {
      teamList: [],
      addteamform: false,
      weekoff: [],
      addteamName: "",
      editWeekoff: [],
      editTeamName: "",
      editteamform: false,
      editTeamId: "",
    };
  },
  watch: {},

  computed: {},
  mounted() {
    this.getTeams();
  },
  methods: {
    // -------------------------get Teams
    getTeams() {
      this.team = [];
      let url = `${constants.SERVER_API}team`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("team list", response.data.data);
          this.teamList = response.data.data;

          this.teamList.forEach((element) => {
            this.team.push({
              text: element.name,
              id: element.id,
            });
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    addTeam() {
      this.addteamform = true;
    },
    createTeam() {
      let obj = {
        name: this.addteamName,
        week_off: this.weekoff.join(),
      };
      console.log("obj", obj);
      let url = `${constants.SERVER_API}team`;

      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("this.finalAttendance", response);
          this.getTeams();
          this.$vs.notify({
            title: "New Team",
            text: "Successfully Added",
            color: "success",
          });
          this.addteamName = "";
          this.weekoff = "";
          this.addteamform = false;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    editTeam(id, name, week_off) {
      console.log("id", id);
      console.log("name", name);
      let week = week_off.split(",");
      //   console.log("week_off",week.length)
      //   if(week_off)
      this.editTeamId = id;
      this.editTeamName = name;
      this.editWeekoff = week;
      this.editteamform = true;
    },
    editingnTeam() {
      let obj = {
        name: this.editTeamName,
        week_off: this.editWeekoff.join(),
      };
      console.log("obj", obj);
      let url = `${constants.SERVER_API}team/${this.editTeamId}`;

      axios
        .put(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.$vs.notify({
            title: "Team",
            text: "Successfully Updated",
            color: "success",
          });
          this.getTeams();
          this.addteamName = "";
          this.weekoff = "";
          this.editteamform = false;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    deleteTeam(id, name, week_off) {
      console.log(week_off);
      let url = `${constants.SERVER_API}team/${id}`;

      axios
        .delete(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response)
          this.getTeams();
          this.$vs.notify({
            title: "Team",
            text: "Successfully Deleted",
            color: "success",
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
<style>
.vs-con-table {
  width: 100%;
}
th .vs-table-text {
  /* justify-content: center; */
}
/* td.td.vs-table--td {
    text-align: center;
} */
</style>
